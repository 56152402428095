import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Loader from './component/Loader/Loader';
import NavMotion from './layout/NavMotion';
import MainLayout from './layout/MainLayout';
import GuestGuard from './component/Auth/GuestGuard';
import AuthGuard from './component/Auth/AuthGuard';
import MinimalLayout from './layout/MinimalLayout';
import AssignToEditorView from 'views/ProductionManager/AssignToEditor';
import ReleaseNotes from 'views/ReleaseNotes/ReleaseNotes';
import Notifications from 'views/Notifications/Notifications';

const RequestQueue = lazy(() => import('./views/EditorPage/RequestQueue'));

const AuthLogin = lazy(() => import('./views/Login'));
const AuthForgotPassword = lazy(() => import('./views/ForgotPassword'));


const DashboardDefault = lazy(() => import('./views/Dashboard/Default'));

const Error1 = lazy(() => import('./views/Pages/Error/Error1'));
const Error2 = lazy(() => import('./views/Pages/Error/Error2'));
const Comingsoon = lazy(() => import('./views/Pages/Comingsoon'));

const HeroList = lazy(() => import('./views/Heroes/list'));
const HeroDetail = lazy(() => import('./views/Heroes/Detail'));

const TeamList = lazy(() => import('./views/Teams/list'));
const TeamDetail = lazy(() => import('./views/Teams/Detail'));
const TeamCompareHeroesPhotos = lazy(() => import('./views/Teams/compareHeroesPhotos'));

const CreditList = lazy(() => import('./views/Credits/list'));

const EditorList = lazy(() => import('./views/Editors/list'));
const EditorDetail = lazy(() => import('./views/Editors/Detail'));

const EditorInvoiceReport = lazy(() => import('./views/EditorInvoiceReport'));

const BackgroundsList = lazy(() => import('./views/Backgrounds/list'));

const RequestsList = lazy(() => import('./views/Requests/list'));
const PhotoView = lazy(() => import('./views/PhotoView/PhotoView'));
const AffiliateReferralsList = lazy(() => import('./views/Affiliate/ReferralsList'));
const AffiliateList = lazy(() => import('./views/Affiliate/AffiliateList'));
const LeadsList = lazy(() => import('./views/Leads/LeadList'));

const Routes = () => {
    const location = useLocation();

    return (
        <AnimatePresence>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Redirect exact from="/" to="/dashboard/default" />
                    <Route path={[
                        '/forgot-password', 
                        '/application/forgot-password', 
                        '/application/login', 
                        '/pages/error/error1', 
                        '/pages/error/error2', 
                        '/pages/comingsoon']}>
                        <MinimalLayout >
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <Route path="/application/login" component={AuthLogin} />
                                    <Route path="/application/forgot-password" component={AuthForgotPassword} />
                                    <Route path="/forgot-password" component={AuthForgotPassword} />
                                    <Route path="/pages/error/error1" component={Error1} />
                                    <Route path="/pages/error/error2" component={Error2} />
                                    <Route path="/pages/comingsoon" component={Comingsoon} />
                                </NavMotion>
                            </Switch>
                        </MinimalLayout>
                    </Route>
                    <Route path={[
                        '/editor/:editor_id/uploads/:id/:filename?',
                        '/editor/requests/:public_id']}>
                        <MinimalLayout showAppBar={true}>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    {/* <AuthGuard> */}
                                    <Route path="/editor/:editor_id/uploads/:id/:filename?" component={PhotoView} />
                                    <Route path="/editor/requests/:public_id" component={RequestQueue} />
                                    {/* </AuthGuard> */}
                                </NavMotion>
                            </Switch>
                        </MinimalLayout>
                    </Route>
                    <Route path={['/login']}>
                        <MinimalLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <GuestGuard>
                                        <Route path="/login" component={AuthLogin} />
                                    </GuestGuard>
                                </NavMotion>
                            </Switch>
                        </MinimalLayout>
                    </Route>
                    <Route
                        path={[
                            '/dashboard/default',
                            '/heroes/list',
                            '/heroes/detail/:id',
                            '/teams/list',
                            '/teams/detail/:id',
                            '/teams/compare-heroes-photos/:id',
                            '/credits/list',
                            '/editors/list',
                            '/editors/detail/:id',
                            '/editors/invoice-report',
                            '/backgrounds/list',
                            '/requests/list',
                            '/heroes/uploads/:id',
                            '/production/assign/:request_id?',
                            '/production/deliver/:request_id?',
                            '/production/uat',
                            '/affiliates/referrals',
                            '/affiliates/list',
                            '/leads/list',
                            '/release-notes',
                            '/notifications'
                        ]}
                    >
                        <MainLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <AuthGuard>
                                        <Route path="/dashboard/default" component={DashboardDefault} />
                                        <Route path="/heroes/list" component={HeroList} />
                                        <Route path="/heroes/detail/:id" component={HeroDetail} />
                                        <Route path="/teams/list" component={TeamList} />
                                        <Route path="/teams/detail/:id" component={TeamDetail} />
                                        <Route path="/teams/compare-heroes-photos/:id" component={TeamCompareHeroesPhotos} />
                                        <Route path="/credits/list" component={CreditList} />
                                        <Route path="/editors/list" component={EditorList} />
                                        <Route path="/editors/detail/:id" component={EditorDetail} />
                                        <Route path="/editors/invoice-report" component={EditorInvoiceReport} />
                                        <Route path='/backgrounds/list' component={BackgroundsList} />
                                        <Route path='/requests/list' component={RequestsList} />

                                        <Route path="/heroes/uploads/:id/:filename?" component={PhotoView} />

                                        <Route path='/production/assign' render={(props) => <AssignToEditorView {...props} viewType="assign" />} />
                                        <Route path='/production/deliver' render={(props) => <AssignToEditorView {...props} viewType="deliver" />} />

                                        <Route path='/affiliates/referrals' component={AffiliateReferralsList} />
                                        <Route path='/affiliates/list' component={AffiliateList} />
                                        <Route path='/leads/list' component={LeadsList} />
                                        <Route path='/release-notes' component={ReleaseNotes} />
                                        <Route path='/notifications' component={Notifications} />
                                    </AuthGuard>
                                </NavMotion>
                            </Switch>
                        </MainLayout>
                    </Route>
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;
